.irregular-date-workday {
  background-color: green;
  color: #020202;
}
.irregular-date-holiday {
  background-color: crimson;
  color: #070606;
}
.holiday {
  background-color: yellow;
  color: #070606;
}

.drawingBuffer {
  position: absolute;
  top: 0;
  left: 0;
}

.react-calendar {
  max-width: 100%;
}

@media only screen and (max-width: 600px) {
  .react-calendar {
    width: 100%;
  }
}
